import React from "react";
import { Flex, Input, IconButton, Avatar, Text, Box, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { FiSearch, FiBell, FiLogOut } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

type Notification = {
  id: string;
  message: string;
};

const TopNav: React.FC = () => {
  const navigate = useNavigate();
  
  // Empty notifications list for now
  const notifications: Notification[] = [];

  const handleSignOut = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      padding={4}
      bg="white"
      boxShadow="sm"
      width="100%"
    >
      <Box>
        <Text fontSize="xl" fontWeight="bold">Agnostech AI</Text>
      </Box>
      <Flex align="center" flex={1} justifyContent="center">
        <Input placeholder="Ask AI" width="50%" maxWidth="400px" />
        <IconButton
          aria-label="Search"
          icon={<FiSearch />}
          ml={2}
        />
      </Flex>
      <Flex align="center">
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Notifications"
            icon={<FiBell />}
            mr={4}
          />
          <MenuList>
            {notifications.length === 0 ? (
              <MenuItem>No notifications</MenuItem>
            ) : (
              notifications.map((notification) => (
                <MenuItem key={notification.id}>{notification.message}</MenuItem>
              ))
            )}
          </MenuList>
        </Menu>
        <Menu>
          <MenuButton as={Avatar} size="sm" cursor="pointer" />
          <MenuList>
            <MenuItem icon={<FiLogOut />} onClick={handleSignOut}>
              Sign Out
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};

export default TopNav;