import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import SideNav from "../../components/SideNav";
import TopNav from "../../components/TopNav";
import { Outlet } from "react-router-dom";

const Dashboard: React.FC = () => {

  return (
    <Flex height="100vh" flexDirection="column">
      <TopNav />
      <Flex flex={1} overflow="hidden">
        <Box width="240px" flexShrink={0}>
          <SideNav />
        </Box>
        <Box flex={1} overflowY="auto" p={3}>
          <Outlet />
        </Box>
      </Flex>
    </Flex>
  );
};

export default Dashboard;
