import axios from "axios";
import { BASE_URL } from "../constants/app_constants";

const api = axios.create({
  baseURL: `${BASE_URL}/api`,
});

api.interceptors.response.use(
  (response) => {
    // If the request is successful (status code 2xx), just return the response
    return response;
  },
  (error) => {
    if (axios.isAxiosError(error) && error.response) {
      // Check if the error is an AxiosError and has a response
      if (error.response.data) {
        if (error.response.data.includes("Member Exists")) {
          throw new Error("You're already on the list!");
        } else if (error.response.data.includes("looks fake or invalid")) {
          throw new Error("Please provide a real email address!");
        }
      }
    }

    // Check if the error response exists
    if (error.response) {
      // The request was made, but the server responded with a status code outside the range of 2xx
      const status = error.response.status;
      const message = error.response.data.error || "An error occurred";

      // Handle specific status codes if needed
      if (status >= 400 && status < 500) {
        console.error(`Client error (${status}): ${message}`);
      } else if (status >= 500) {
        console.error(`Server error (${status}): ${message}`);
      } else {
        console.error(`Unexpected error (${status}): ${message}`);
      }

      // Optionally, throw a custom error
      throw new Error(message);
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      throw new Error("No response received from server.");
    } else {
      // Something else happened while setting up the request
      console.error("Error setting up request:", error.message);
      throw new Error("Error setting up request.");
    }
  }
);

export { api };
