import React from "react";
import {
  HStack,
  Text,
  Switch,
  VStack,
  Input,
  Select,
  IconButton,
  Button,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { NodeData } from "../type";

interface VariablesSectionProps {
  nodeData: NodeData;
  setNodeData: React.Dispatch<React.SetStateAction<NodeData>>;
}

const varTypes = [
  { id: 0, name: "String", value: "str" },
  { id: 1, name: "Integer", value: "int" },
  { id: 2, name: "Boolean", value: "bool" },
];

const VariablesSection: React.FC<VariablesSectionProps> = ({
  nodeData,
  setNodeData,
}) => {
  const handleAddVariable = () => {
    const newVariables = [
      ...nodeData.callInfoIntoVariableObject.variables,
      { varName: "", varType: "", varExample: "" },
    ];
    setNodeData({
      ...nodeData,
      callInfoIntoVariableObject: {
        ...nodeData.callInfoIntoVariableObject,
        variables: newVariables,
      },
    });
  };

  const handleDeleteVariable = (index: number) => {
    const newVariables = [...nodeData.callInfoIntoVariableObject.variables];
    newVariables.splice(index, 1);
    setNodeData({
      ...nodeData,
      callInfoIntoVariableObject: {
        ...nodeData.callInfoIntoVariableObject,
        variables: newVariables,
      },
    });
  };

  return (
    <>
      <HStack justifyContent="space-between">
        <Text fontSize="lg" fontWeight="bold" as="p">
          Extract Call Info into Variables:
        </Text>
        <Switch
          isChecked={
            nodeData.callInfoIntoVariableObject.extractInfoIntoVariable
          }
          onChange={() =>
            setNodeData((prev) => ({
              ...nodeData,
              callInfoIntoVariableObject: {
                ...nodeData.callInfoIntoVariableObject,
                extractInfoIntoVariable:
                  !prev.callInfoIntoVariableObject.extractInfoIntoVariable,
              },
            }))
          }
        />
      </HStack>
      {nodeData.callInfoIntoVariableObject.variables.map((variable, index) => (
        <VStack key={index} width="100%" mt={2}>
          <HStack width="100%">
            <Input
              placeholder="Variable Name"
              value={variable.varName}
              onChange={(e) => {
                const newVariables = [
                  ...nodeData.callInfoIntoVariableObject.variables,
                ];
                newVariables[index].varName = e.target.value;
                setNodeData({
                  ...nodeData,
                  callInfoIntoVariableObject: {
                    ...nodeData.callInfoIntoVariableObject,
                    variables: newVariables,
                  },
                });
              }}
            />
            <Select
              value={variable.varType}
              placeholder="Select Option"
              onChange={(e) => {
                const newVariables = [
                  ...nodeData.callInfoIntoVariableObject.variables,
                ];
                newVariables[index].varType = e.target.value;
                setNodeData({
                  ...nodeData,
                  callInfoIntoVariableObject: {
                    ...nodeData.callInfoIntoVariableObject,
                    variables: newVariables,
                  },
                });
              }}
            >
              {varTypes.map((vartype) => (
                <option key={vartype.id} value={vartype.value}>
                  {vartype.name}
                </option>
              ))}
            </Select>
            <IconButton
              icon={<DeleteIcon />}
              aria-label="Delete"
              colorScheme="red"
              onClick={() => handleDeleteVariable(index)}
            />
          </HStack>
          <Input
            placeholder="Variable Example"
            value={variable.varExample}
            onChange={(e) => {
              const newVariables = [
                ...nodeData.callInfoIntoVariableObject.variables,
              ];
              newVariables[index].varExample = e.target.value;
              setNodeData({
                ...nodeData,
                callInfoIntoVariableObject: {
                  ...nodeData.callInfoIntoVariableObject,
                  variables: newVariables,
                },
              });
            }}
          />
        </VStack>
      ))}
      <Button colorScheme="blue" size="sm" onClick={handleAddVariable} mt={2}>
        + Add Variable
      </Button>
    </>
  );
};

export default VariablesSection;
