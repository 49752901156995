import React, { useEffect, useRef, useState } from "react";
import { Button, Input, Box, VStack, Spinner, Badge } from "@chakra-ui/react";

import { BASE_WS_URL } from "../../constants/app_constants";
import MDEditor from "@uiw/react-md-editor";

const ChatAgent: React.FC<{ config: any; graphType: string }> = ({
  config,
  graphType,
}) => {
  const socketRef = useRef<WebSocket | null>(null); // Use useRef for WebSocket
  const [messages, setMessages] = useState<{ type: string; value: string }[]>(
    []
  );
  const [graphInitialised, setGraphInitialised] = useState(false);
  const [chatEnabled, setChatEnabled] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [waitingForResponse, setWaitingForResponse] = useState(false);

  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null); // Input ref

  useEffect(() => {
    if (!socketRef.current) {
      // Establish WebSocket connection if not already connected
      connectWebSocket();
    }

    return () => {
      // Cleanup on unmount
      disconnectWebSocket();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array ensures this runs once on mount and cleanup on unmount

  useEffect(() => {
    if (chatEnabled && inputRef.current && !waitingForResponse) {
      inputRef.current.focus(); // Focus input when chat is enabled
    }
  }, [chatEnabled, waitingForResponse]);

  const connectWebSocket = () => {
    const ws = new WebSocket(`${BASE_WS_URL}/websocket/`);
    socketRef.current = ws;
    setLoading(true);

    ws.onmessage = handleMessage;
    ws.onopen = () => {
      ws.send(
        JSON.stringify({
          initConfig: {
            chatConfig: {
              config: config,
              graphType: graphType,
            },
          },
        })
      );
    };
    ws.onclose = () => {
      setLoading(false); // Set loading to false when disconnected
      socketRef.current = null; // Clear the ref
    };
    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
      setLoading(false);
    };
  };

  const handleMessage = (event: MessageEvent) => {
    const message = JSON.parse(event.data);
    if (
      "status" in message &&
      message["status"].toLowerCase() === "graph initialized"
    ) {
      setGraphInitialised(true);
      setLoading(false); // Set loading to false when connected
    } else if ("response" in message) {
      setWaitingForResponse(false);
      setMessages((prev) => [
        ...prev,
        { type: "response", value: message["response"] },
      ]);
    }
  };

  const disconnectWebSocket = () => {
    if (socketRef.current) {
      socketRef.current.close();
      socketRef.current = null;
    }
  };

  const sendMessage = () => {
    if (socketRef.current && inputValue) {
      setWaitingForResponse(true);
      setMessages((prev) => [...prev, { type: "query", value: inputValue }]);
      socketRef.current.send(JSON.stringify({ question: inputValue }));
      setInputValue("");
    }
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    if (graphInitialised) {
      setChatEnabled(true);
    }
  }, [graphInitialised]);

  return (
    <VStack spacing={4} alignItems={"start"}>
      <Badge colorScheme={graphInitialised ? "green" : "red"}>
        {graphInitialised ? "Connected" : "Connecting"}
      </Badge>
      {loading && <Spinner />} {/* Show loading spinner */}
      <Box
        borderWidth="1px"
        borderRadius="lg"
        p={4}
        width="100%"
        height={"400px"}
        overflowY="auto"
      >
        {messages.map((msg, index) => (
          <Box
            key={index}
            textAlign="left"
            margin={msg.type === "query" ? "0 auto 10px 0" : "0 0 10px auto"}
            bg={msg.type === "query" ? "blue.50" : "green.50"}
            p={2}
            borderRadius="md"
            width="fit-content"
            maxWidth="90%"
          >
            <Box data-color-mode="light" overflow={"auto"}>
              <MDEditor.Markdown
                source={msg.value}
                style={{
                  whiteSpace: "pre-wrap",
                  backgroundColor: "transparent",
                }}
              />
            </Box>
          </Box>
        ))}
        <div ref={messagesEndRef} />
      </Box>
      {chatEnabled && (
        <Box display="flex" width="100%" gap={2} mb={2}>
          <Input
            ref={inputRef} // Attach the ref to the input
            isDisabled={waitingForResponse}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Type your message"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                sendMessage(); // Trigger sendMessage on Enter key press
              }
            }}
          />
          <Button
            colorScheme="blue"
            onClick={sendMessage}
            isDisabled={waitingForResponse}
          >
            Send
          </Button>
        </Box>
      )}
    </VStack>
  );
};

export default ChatAgent;
