import React from "react";
import { Box, VStack, Icon, Text, Flex, Divider } from "@chakra-ui/react";
import {
  FiHome,
  FiBook,
  FiTarget,
  FiUsers,
  FiBarChart2,
  FiPhoneCall,
  FiMessageSquare,
  FiSettings,
  FiTrello,
} from "react-icons/fi";
import { NavLink, useLocation } from "react-router-dom";

const SideNav: React.FC = () => {
  const location = useLocation();

  const isExactPath = (path: string) => location.pathname === path;

  return (
    <Box
      width="240px"
      bg="white"
      boxShadow="md"
      height="100%"
      py={6}
      display="flex"
      flexDirection="column"
    >
      <VStack spacing={4} align="stretch" flex={1}>
        <NavSection title="You">
          <NavLink to="/dashboard" end>
            {() => (
              <NavItem
                icon={FiHome}
                label="Dashboard"
                isActive={isExactPath("/dashboard")}
              />
            )}
          </NavLink>
          <NavLink to={"/dashboard/knowledge-base"}>
            {({ isActive }) => (
              <NavItem
                icon={FiBook}
                label="Knowledge Base"
                isActive={isActive}
              />
            )}
          </NavLink>
          <NavLink to={"/dashboard/campaigns"}>
            {({ isActive }) => (
              <NavItem
                icon={FiTarget}
                label="Campaigns"
                isActive={isActive}
              />
            )}
          </NavLink>
        </NavSection>

        <Divider my={4} />

        <NavSection title="Company">
          <NavLink to={"/dashboard/agents"}>
            {({ isActive }) => (
              <NavItem
                icon={FiUsers}
                label="Agents"
                isActive={isActive}
              />
            )}
          </NavLink>

          <NavLink to={"/dashboard/voices"}>
            {({ isActive }) => (
              <NavItem
                icon={FiTrello}
                label="Voices"
                isActive={isActive}
              />
            )}
          </NavLink>

          <NavLink to={"/dashboard/agent-metrics"}>
            {({ isActive }) => (
              <NavItem
                icon={FiBarChart2}
                label="Agent Metrics"
                isActive={isActive}
              />
            )}
          </NavLink>

          <NavLink to={"/dashboard/contacts"}>
            {({ isActive }) => (
              <NavItem
                icon={FiPhoneCall}
                label="Contacts"
                isActive={isActive}
              />
            )}
          </NavLink>

          <NavLink to={"/dashboard/chats"}>
            {({ isActive }) => (
              <NavItem
                icon={FiMessageSquare}
                label="Chats"
                isActive={isActive}
              />
            )}
          </NavLink>
        </NavSection>
      </VStack>

      <Box mt="auto">
        <NavLink to={"/dashboard/settings"}>
          {({ isActive }) => (
            <NavItem
              icon={FiSettings}
              label="Settings"
              isActive={isActive}
            />
          )}
        </NavLink>
      </Box>
    </Box>
  );
};

const NavSection: React.FC<{ title: string; children: React.ReactNode }> = ({
  title,
  children,
}) => (
  <Box>
    <Text fontSize="sm" fontWeight="bold" color="gray.500" mb={2} px={6}>
      {title}
    </Text>
    <VStack spacing={1} align="stretch">
      {children}
    </VStack>
  </Box>
);

// Update NavItem to include onClick prop
const NavItem: React.FC<{
  icon: any;
  label: string;
  isActive: boolean;
}> = ({ icon, label, isActive }) => (
  <Flex
    align="center"
    px={6}
    py={2}
    cursor="pointer"
    bg={isActive ? "blue.50" : "transparent"}
    color={isActive ? "blue.500" : "inherit"}
    fontWeight={isActive ? "bold" : "normal"}
    _hover={{ bg: isActive ? "blue.50" : "gray.100" }}
  >
    <Icon as={icon} mr={4} />
    <Text fontSize="sm">{label}</Text>
  </Flex>
);

export default SideNav;
