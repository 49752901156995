import { useState } from "react";
import { NodeData } from "../type";
import { sendChatMessage, AIType } from "../../../api/chat";
import { promptImprovementPrompt as defaultPromptImprovementPrompt } from "../../../constants/prompts/prompt_improvement";

export const useImprovedPrompt = (
  nodeData: NodeData,
  setNodeData: React.Dispatch<React.SetStateAction<NodeData>>
) => {
  const [improvedPrompt, setImprovedPrompt] = useState<string>("");
  const [isImprovedPromptVisible, setIsImprovedPromptVisible] = useState(false);

  const handleImprove = async () => {
    try {
      const customPromptImprovementPrompt = localStorage.getItem(
        "customPromptImprovementPrompt"
      );
      const chatRequest = {
        history: [],
        message: nodeData.prompt,
        context:
          customPromptImprovementPrompt || defaultPromptImprovementPrompt,
        ai: AIType.GPT,
        power: "high",
      };
      const response = await sendChatMessage(chatRequest);
      setImprovedPrompt(response.response);
      setIsImprovedPromptVisible(true);
    } catch (error) {
      console.error("Error improving prompt:", error);
    }
  };

  const handleApply = () => {
    setNodeData({ ...nodeData, prompt: improvedPrompt });
    setIsImprovedPromptVisible(false);
  };

  const handleIgnore = () => {
    setIsImprovedPromptVisible(false);
  };

  return {
    improvedPrompt,
    isImprovedPromptVisible,
    handleImprove,
    handleApply,
    handleIgnore,
  };
};
