import { useState, useCallback } from "react";

import {
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Textarea,
  Divider,
  Text,
  Input,
  Box,
  HStack,
  Select,
  IconButton,
  Switch,
  Collapse,
  Flex,
} from "@chakra-ui/react";
import { UnlockIcon, DeleteIcon, LockIcon } from "@chakra-ui/icons";
import { useReactFlow } from "@xyflow/react";
import { BsLightbulb } from "react-icons/bs";
import { sendChatMessage, AIType } from "../../api/chat";
import { promptImprovementPrompt } from "../../constants/prompts/prompt_improvement";


import { DrawerNodeFormProps, Tool } from "./type";

const DrawerNodeForm = ({
  id,
  isOpen,
  onClose,
  data,
  allToolsOptions,
  allSensitiveToolsOptions,
}: DrawerNodeFormProps) => {
  const { updateNodeData } = useReactFlow();

  const [toolsOptions, setToolsOptions] = useState<Tool[]>(allToolsOptions);
  const [label, setLabel] = useState<string>(data.label);
  const [prompt, setPrompt] = useState<string>(data.prompt);
  const [tools, setTools] = useState<Tool[]>(data.tools);
  const [selectedTool, setSelectedTool] = useState<string>("");

  const [sensitiveToolsOptions, setSensitiveToolsOptions] = useState<Tool[]>(
    allSensitiveToolsOptions
  );
  const [sensitiveTools, setSensitiveTools] = useState<Tool[]>(
    data.sensitiveTools
  );
  const [selectedSensitiveTool, setSelectedSensitiveTool] =
    useState<string>("");

  const [allowWebSearch, setAllowWebSearch] = useState<boolean>(
    data.allowWebSearch
  );

  const [improvedPrompt, setImprovedPrompt] = useState<string>("");
  const [isImprovedPromptVisible, setIsImprovedPromptVisible] = useState(false);

  const handleImprove = async () => {
    try {
      const chatRequest = {
        history: [],
        message: prompt,
        context: promptImprovementPrompt,
        ai: AIType.GPT,
        power: "high"
      };
      const response = await sendChatMessage(chatRequest);
      setImprovedPrompt(response.response);
      setIsImprovedPromptVisible(true);
    } catch (error) {
      console.error("Error improving prompt:", error);
    }
  };

  const handleApply = () => {
    setPrompt(improvedPrompt);
    setIsImprovedPromptVisible(false);
  };

  const handleIgnore = () => {
    setIsImprovedPromptVisible(false);
  };

  const handleNodeUpdate = useCallback(() => {
    updateNodeData(id, {
      label,
      prompt,
      tools,
      sensitiveTools,
      allowWebSearch,
    });
    onClose();
  }, [
    label,
    prompt,
    tools,
    sensitiveTools,
    id,
    updateNodeData,
    onClose,
    allowWebSearch,
  ]);

  const handleToolsSelect = (index: number) => {
    const optionIndex = toolsOptions.findIndex((obj) => obj.id === index);
    const updatedTools = [...tools, toolsOptions[optionIndex]];
    setTools(updatedTools);

    const filteredTools = toolsOptions.filter((tool) => tool.id !== index);
    setToolsOptions([...filteredTools]);
    setSelectedTool("");
  };

  const handleToolDelete = (id: number) => {
    const index = tools.findIndex((obj) => obj.id === id);
    const newTools = [...tools];
    newTools.splice(index, 1);
    setTools(newTools);

    const filteredTools = allToolsOptions.filter((tool) => tool.id === id);
    const newToolsOptinos = [...toolsOptions, ...filteredTools];
    setToolsOptions(newToolsOptinos);
  };

  const handleSensitiveToolSelect = (index: number) => {
    const optionIndex = sensitiveToolsOptions.findIndex(
      (obj) => obj.id === index
    );
    const updatedTools = [
      ...sensitiveTools,
      sensitiveToolsOptions[optionIndex],
    ];
    setSensitiveTools(updatedTools);

    const filteredTools = sensitiveToolsOptions.filter(
      (tool) => tool.id !== index
    );
    setSensitiveToolsOptions([...filteredTools]);
    setSelectedSensitiveTool("");
  };

  const handleSensitiveToolDelete = (id: number) => {
    const index = sensitiveTools.findIndex((obj) => obj.id === id);
    const newTools = [...sensitiveTools];
    newTools.splice(index, 1);
    setSensitiveTools(newTools);

    const filteredTools = allSensitiveToolsOptions.filter(
      (tool) => tool.id === id
    );
    const newToolsOptinos = [...sensitiveToolsOptions, ...filteredTools];
    setSensitiveToolsOptions(newToolsOptinos);
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size={"lg"}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <Text as={"h1"}> Customise Node</Text>
        </DrawerHeader>

        <Divider mb={8} />

        <DrawerBody>
          <>
            <Text fontSize={"lg"} fontWeight={"bold"} as={"p"}>
              Node name:
            </Text>
            <Input
              variant="outline"
              placeholder="Outline"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
            />
          </>
          <Divider my={4} />
          <>
            <Text fontSize={"lg"} fontWeight={"bold"} as={"p"}>
              Prompt:
            </Text>
            <Flex alignItems="center" justifyContent="space-between" mb={2}>
              <Text fontSize={"xs"}>What the tool will start with.</Text>
              <Button
                size="xs"
                bg="white"
                color="gray.600"
                boxShadow="md"
                _hover={{ bg: "gray.100" }}
                onClick={handleImprove}
                leftIcon={<BsLightbulb size="0.8em" />}
                iconSpacing={1}
              >
                Improve
              </Button>
            </Flex>
            <Box position="relative">
              <Textarea
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                placeholder="Provide your prompt"
                size="md"
                resize={"vertical"}
                minHeight="150px"
              />
            </Box>
            <Collapse in={isImprovedPromptVisible} animateOpacity>
              <Box mt={4} position="relative">
                <Textarea
                  value={improvedPrompt}
                  onChange={(e) => setImprovedPrompt(e.target.value)}
                  placeholder="Improved prompt will appear here"
                  size="md"
                  resize={"vertical"}
                  pr="110px" // Increased right padding to accommodate buttons
                  minHeight="150px"
                />
                <Flex
                  position="absolute"
                  bottom="8px"
                  right="8px"
                  zIndex={2}
                >
                  <Button
                    size="xs"
                    variant="outline"
                    mr={2}
                    onClick={handleIgnore}
                    bg="white"
                  >
                    Ignore
                  </Button>
                  <Button
                    size="xs"
                    colorScheme="blue"
                    onClick={handleApply}
                  >
                    Apply
                  </Button>
                </Flex>
              </Box>
            </Collapse>
          </>
          {data.isPrimaryNode && (
            <>
              <Divider my={4} />
              <>
                <Text fontSize={"lg"} fontWeight={"bold"} as={"p"}>
                  Allow Web Search:
                </Text>
                <Switch
                  isChecked={allowWebSearch}
                  onChange={() => setAllowWebSearch((prevState) => !prevState)}
                />
              </>
            </>
          )}
          <Divider my={4} />
          <>
            <Text fontSize={"lg"} fontWeight={"bold"} as={"p"}>
              Tools:
            </Text>
            {tools.length > 0 &&
              tools.map((tool) => {
                return (
                  <Box
                    key={tool.id}
                    p={2}
                    bgColor={"gray.100"}
                    borderRadius={5}
                    my={4}
                  >
                    <HStack
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <HStack maxW={"80%"}>
                        <UnlockIcon fontSize={"lg"} />
                        <Text
                          as={"p"}
                          fontSize={"lg"}
                          isTruncated
                          maxWidth="100%"
                          whiteSpace="nowrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                        >
                          {tool.name}
                        </Text>
                      </HStack>

                      <IconButton
                        isRound={true}
                        colorScheme="red"
                        aria-label="Delete tool"
                        icon={<DeleteIcon />}
                        size={"xs"}
                        onClick={() => handleToolDelete(tool.id)}
                      />
                    </HStack>

                    <Divider />
                    <Text as={"p"} fontSize={"xs"}>
                      {tool.description}
                    </Text>
                  </Box>
                );
              })}
            <Select
              placeholder="Select tools"
              value={selectedTool}
              onChange={(e) => {
                handleToolsSelect(Number.parseInt(e.target.value));
              }}
            >
              {toolsOptions.map((tool) => (
                <option key={tool.id} value={tool.id}>
                  {tool.name}
                </option>
              ))}
            </Select>
          </>
          <Divider my={4} />
          <>
            <Text fontSize={"lg"} fontWeight={"bold"} as={"p"}>
              Sensitive Tools:
            </Text>
            {sensitiveTools.length > 0 &&
              sensitiveTools.map((tool) => {
                return (
                  <Box
                    key={tool.id}
                    p={2}
                    bgColor={"gray.100"}
                    borderRadius={5}
                    my={4}
                  >
                    <HStack
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <HStack maxW={"80%"}>
                        <LockIcon fontSize={"lg"} />
                        <Text
                          as={"p"}
                          fontSize={"lg"}
                          isTruncated
                          maxWidth="100%"
                          whiteSpace="nowrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                        >
                          {tool.name}
                        </Text>
                      </HStack>

                      <IconButton
                        isRound={true}
                        colorScheme="red"
                        aria-label="Delete tool"
                        icon={<DeleteIcon />}
                        size={"xs"}
                        onClick={() => handleSensitiveToolDelete(tool.id)}
                      />
                    </HStack>

                    <Divider />
                    <Text as={"p"} fontSize={"xs"}>
                      {tool.description}
                    </Text>
                  </Box>
                );
              })}
            <Select
              placeholder="Select tools"
              value={selectedSensitiveTool}
              onChange={(e) => {
                handleSensitiveToolSelect(Number.parseInt(e.target.value));
              }}
            >
              {sensitiveToolsOptions.map((tool) => (
                <option key={tool.id} value={tool.id}>
                  {tool.name}
                </option>
              ))}
            </Select>
          </>
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleNodeUpdate}>
            Save
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default DrawerNodeForm;
