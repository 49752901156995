import React, { useCallback, useState, useEffect, useMemo } from "react";
import { nanoid } from "nanoid";
import {
  Box,
  Button,
  HStack,
  Input,
  useToast,
  VStack,
  Flex,
  useDisclosure,
  Textarea,
  Select,
} from "@chakra-ui/react";
import {
  ReactFlow,
  Background,
  applyNodeChanges,
  applyEdgeChanges,
  addEdge,
  BackgroundVariant,
  Panel,
  Controls,
  Node,
  Edge,
  OnConnect,
  OnNodesChange,
  OnEdgesChange,
  NodeTypes,
  EdgeTypes,
  ControlButton,
} from "@xyflow/react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import "@xyflow/react/dist/style.css";
import { FaUndo, FaRedo } from "react-icons/fa";

import ChatAgent from "../../components/ChatAgent/ChatAgent";
import ModalWrapper from "../../components/ModalWrapper";

import DefaultNode from "../../components/PathWayNodes/DefaultNode";
import EndCallNode from "../../components/PathWayNodes/EndCallNode";
import ToolNode from "../../components/PathWayNodes/ToolNode";
import KnowledgeBaseNode from "../../components/PathWayNodes/KnowledgeBaseNode";

import PathWayEdge from "../../components/PathWayEdges/PathWayEdge";

import NewNodeList from "./NewNodeList";
import useRetellWebCall from "../../hooks/useRetellWebCall";

import {
  getOrganisationAgent,
  updateOrganisationAgent,
  createOrganisationAgent,
} from "../../api/agents";
import { AgentConfig } from "../../components/Agents/type";
import { GraphType, getVoiceConfigurations } from "../../api/voice";
import { BASE_WS_URL } from "../../constants/app_constants";
import { availableNodeTypes } from "./nodeTypes";
import MarkDownEditor from "../../components/MarkDownEditor";
import SMSNode from "../../components/PathWayNodes/SMSNode";

export const areNodesEqual = (nodeA: Node, nodeB: Node): boolean => {
  const {
    position: positionA,
    selected: selectedA,
    measured: measuredA,
    dragging: draggingA,
    ...restA
  } = nodeA; // Destructure to ignore position
  const {
    position: positionB,
    selected: selectedB,
    measured: measuredB,
    dragging: draggingB,
    ...restB
  } = nodeB; // Ignore position in nodeB
  const isSame = JSON.stringify(restA) === JSON.stringify(restB);
  return isSame; // Compare the rest of the properties
};

const CPWBuilder: React.FC = () => {
  const { agentId } = useParams<{ agentId: string | undefined }>();
  const navigate = useNavigate();
  const toast = useToast();
  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure(); // Existing drawer modal state
  const {
    isOpen: isGlobalPromptOpen,
    onOpen: onGlobalPromptOpen,
    onClose: onGlobalPromptClose,
  } = useDisclosure();
  const {
    isOpen: isChatModalOpen,
    onOpen: onChatModalOpen,
    onClose: onChatModalClose,
  } = useDisclosure();
  const {
    isOpen: isGlobalKnowledgeOpen,
    onOpen: onGlobalKnowledgeOpen,
    onClose: onGlobalKnowledgeClose,
  } = useDisclosure();
  const [baseInformation, setBaseInformation] = useState<{ text: string }>({
    text: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [history, setHistory] = useState<{ nodes: Node[]; edges: Edge[] }[]>([
    { nodes: [], edges: [] },
  ]); // Combined history
  const [historyIndex, setHistoryIndex] = useState(0); // Current index in history

  const [callNumber, setCallNumber] = useState("");
  const [agentName, setAgentName] = useState("");
  const [globalPrompt, setGlobalPrompt] = useState("");
  const [isTestButtonDisabled, setIsTestButtonDisabled] = useState(false);
  const [chatConfig, setChatConfig] = useState<{
    nodes: Node[];
    edges: Edge[];
    globalPrompt: string;
    baseInformation: any;
  }>(); // State for chat configuration
  const [voiceConfigs, setVoiceConfigs] = useState<any[]>([]);
  const [selectedVoiceId, setSelectedVoiceId] = useState<string>("");

  const nodeTypes: NodeTypes = useMemo(
    () => ({ DefaultNode, EndCallNode, KnowledgeBaseNode, ToolNode, SMSNode }),
    []
  );
  const edgeTypes: EdgeTypes = useMemo(() => ({ PathWayEdge }), []);
  const {
    initiateCall,
    stopCall,
    isCallActive,
    isLoading: isRetellCallLoading,
  } = useRetellWebCall();

  useEffect(() => {
    if (agentId) {
      getAgentState();
    }
  }, [agentId]);

  const areExtractionsValid = (nodes: Node[]) => {
    let valid: boolean = true;
    let errorNodes: string[] = [];

    nodes.forEach((node: Node) => {
      const label = (node.data.label as string).toLowerCase().trim();
      if (
        (
          node.data.callInfoIntoVariableObject as {
            extractInfoIntoVariable: boolean;
          }
        ).extractInfoIntoVariable
      ) {
        const fields = (
          node.data.callInfoIntoVariableObject as {
            variables: {
              varName: string;
              varType: string;
              varExample: string;
            }[];
          }
        ).variables;

        if (fields.length === 0) {
          valid = false;
          errorNodes.push(label);
        }

        fields.forEach((field) => {
          if (
            field.varName.trim() === "" ||
            field.varType.trim() === "" ||
            field.varExample.trim() === ""
          ) {
            valid = false;
            errorNodes.push(label);
          }
        });
      }
    });

    return { valid, errorNodes };
  };

  const areNodesLabelsValid = (nodes: Node[]) => {
    let valid: boolean = true;
    let nodeLabels = new Set();
    let errorNodes: string[] = [];

    nodes.forEach((node) => {
      const label = (node.data.label as string).toLowerCase().trim();
      if (nodeLabels.has(label)) {
        valid = false;
        errorNodes.push(label);
      } else {
        nodeLabels.add(label);
      }
    });
    return { valid, errorNodes };
  };

  const handleApiError = (error: unknown) => {
    const errorMessage =
      error instanceof Error
        ? error.message
        : axios.isAxiosError(error) && error.response?.data
        ? error.response.data
        : "Something went wrong. Please try again later.";
    toast({
      title: "Error",
      description: errorMessage,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };

  const getAgentState = useCallback(async () => {
    setIsLoading(true);
    try {
      const response: AgentConfig = await getOrganisationAgent("1", agentId!);
      setNodes(response.configuration.nodes);
      setEdges(response.configuration.edges);
      // set history
      setHistory([
        {
          nodes: response.configuration.nodes,
          edges: response.configuration.edges,
        },
      ]);

      setGlobalPrompt(response.configuration.globalPrompt);
      let baseInformation = response.configuration.baseInformation;

      if (baseInformation == null) {
        baseInformation = {
          text: "",
        };
      }
      setBaseInformation(baseInformation);
      setCallNumber(response.callNumber);
      if (response.agentName.trim() === "") {
        setAgentName(response.agentId);
      } else {
        setAgentName(response.agentName);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  }, [agentId]);

  const updateHistory = (newNodes: Node[], newEdges: Edge[]) => {
    const newHistory = [
      ...history.slice(0, historyIndex + 1),
      { nodes: newNodes, edges: newEdges },
    ];
    if (newHistory.length > 30) newHistory.shift(); // Limit history to 30
    setHistory(newHistory);
    setHistoryIndex(newHistory.length - 1);
  };

  const onNodesChange: OnNodesChange = useCallback(
    (changes) => {
      const updatedNodes = applyNodeChanges(changes, nodes);
      if (
        updatedNodes.length !== nodes.length ||
        !nodes.every((node, index) => areNodesEqual(node, updatedNodes[index]))
      ) {
        updateHistory(updatedNodes, edges);
      }
      setNodes(updatedNodes);
    },
    [nodes, edges]
  );

  const onEdgesChange: OnEdgesChange = useCallback(
    (changes) => {
      const updatedEdges = applyEdgeChanges(changes, edges);
      if (
        updatedEdges.length !== edges.length ||
        !edges.every(
          (edge, index) =>
            JSON.stringify(edge) === JSON.stringify(updatedEdges[index])
        )
      ) {
        updateHistory(nodes, updatedEdges);
      }
      setEdges(updatedEdges);
    },
    [nodes, edges]
  );

  const undo = () => {
    if (historyIndex > 0) {
      setHistoryIndex(historyIndex - 1);
      setNodes(history[historyIndex - 1].nodes);
      setEdges(history[historyIndex - 1].edges);
    }
  };

  const redo = () => {
    if (historyIndex < history.length - 1) {
      setHistoryIndex(historyIndex + 1);
      setNodes(history[historyIndex + 1].nodes);
      setEdges(history[historyIndex + 1].edges);
    }
  };

  const handleTestAgent = async () => {
    if (isTestButtonDisabled) return;

    if (isCallActive) {
      stopCall();
    } else {
      setIsTestButtonDisabled(true);
      try {
        await initiateCall(
          { nodes, edges, globalPrompt },
          GraphType.CPW,
          selectedVoiceId
        );
      } catch (error) {
        toast({
          title: "Error",
          description: "Failed to initiate call. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsTestButtonDisabled(false);
      }
    }
  };

  const saveAgentState = useCallback(async () => {
    if (nodes.length === 0) {
      return;
    }

    const { valid, errorNodes } = areNodesLabelsValid(nodes);

    if (!valid) {
      let message = `There are duplicate node labels. Please check following nodes: ${errorNodes.join(
        ", "
      )}.`;
      if (errorNodes.length === 1) {
        message = `There is a duplicate node label. Please check the node: ${errorNodes.join(
          ", "
        )}.`;
      }
      alert(message);
      return;
    }

    const { valid: eValid, errorNodes: eErrorNodes } =
      areExtractionsValid(nodes);

    console.log(eValid, eErrorNodes);

    if (!eValid) {
      let message = `Variable extraction is enabled but fields are not set. Please check following nodes: ${eErrorNodes.join(
        ", "
      )}.`;
      if (eErrorNodes.length === 1) {
        message = `Variable extraction is enabled but fields are not set. Please check the node: ${eErrorNodes.join(
          ", "
        )}.`;
      }
      alert(message);
      return;
    }

    const filteredCallNumber = callNumber.length < 10 ? "NA" : callNumber;
    const data = {
      configuration: { nodes, edges, globalPrompt, baseInformation },
      updatedBy: "test@test.com",
      callNumber: filteredCallNumber,
      agentName: agentName,
      type: "cpw",
      voiceId: selectedVoiceId,
    };

    try {
      setIsLoading(true);
      if (agentId) {
        await updateOrganisationAgent("1", agentId, data);
      } else {
        const agent: AgentConfig = await createOrganisationAgent("1", data);
        navigate(`/dashboard/agent/cpw/${agent.agentId}`, { replace: true });
      }
      toast({
        title: "Success",
        description: "Agent state saved!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  }, [
    agentId,
    agentName,
    nodes,
    edges,
    callNumber,
    globalPrompt,
    baseInformation,
    selectedVoiceId,
  ]);

  const getVoiceConfig = useCallback(async () => {
    try {
      const response = await getVoiceConfigurations();
      if (response) {
        // Filter voice configs based on BASE_WS
        const filteredConfigs = response.filter((config: any) =>
          config.llm_websocket_url.includes(BASE_WS_URL)
        );
        setVoiceConfigs(filteredConfigs);
        // Set the first voice config as default if available
        if (filteredConfigs.length > 0) {
          setSelectedVoiceId(filteredConfigs[0].agent_id);
        }
      }
      return response;
    } catch (err: any) {
      console.log(err);
      toast({
        title: "Error",
        description: "Failed to get voice configurations. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, []);

  useEffect(() => {
    getVoiceConfig();
  }, [getVoiceConfig]);

  const onConnect: OnConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge(
          {
            ...params,
            type: "PathWayEdge",
            data: {
              edgeCondition: "condition to next pathway",
              edgeDescription: "",
            },
            animated: true,
            style: { stroke: "blue" },
          },
          eds
        )
      ),
    []
  );

  const handleAddNode = useCallback(
    (nodeType: string) => {
      const selectedNode = availableNodeTypes.filter(
        (node) => node.type === nodeType
      )[0];

      const nodeId = nanoid();
      let isPrimaryNode = false;
      if (nodes.length === 0) {
        isPrimaryNode = true;
      }

      const newNode: Node = {
        id: nodeId,
        data: {
          label: "New Node",
          prompt: "",
          knowledgeBaseText: "",
          loopConditionObject: {
            loopCondition: false,
            loopConditionExample: "",
          },
          globalNodeObject: {
            globalNode: false,
            globalEdgeCondition: "",
            globalEdgeDescription: "",
          },
          isPrimaryNode: isPrimaryNode,
          allowWebSearch: false,
          advancedOptionObject: {
            modelType: "higher_intelligence_model",
            temperature: 0.2,
          },
          callInfoIntoVariableObject: {
            extractInfoIntoVariable: false,
            variables: [{ varName: "", varType: "", varExample: "" }],
          },
          safeTools: [],
          sensitiveTools: [],
          nodeInfo: {
            name: selectedNode.name,
            type: selectedNode.type,
            icon: selectedNode.icon,
          },
          staticText: false,
        },
        type: nodeType,
        position: {
          x: Math.random() * 500,
          y: Math.random() * 300,
        },
      };
      setNodes((nds) => nds.concat(newNode));
      updateHistory([...nodes, newNode], edges);
    },
    [nodes]
  );

  const handleCallNumberInput = useCallback((inputValue: string) => {
    const parsedValue = inputValue.replace(/\D/g, ""); // Remove non-numeric characters
    if (parsedValue.length <= 12) {
      setCallNumber(parsedValue || "");
    }
  }, []);

  const openChatModal = () => {
    setChatConfig({ nodes, edges, globalPrompt, baseInformation }); // Set the configuration data
    onChatModalOpen();
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const isMac = /Mac|iPod|iPhone|iPad/.test(navigator.userAgent); // Check if on Mac
      if (isMac ? event.metaKey : event.ctrlKey) {
        // Use metaKey for Mac
        if (event.key === "z") {
          event.preventDefault(); // Prevent default behavior
          undo(); // Call undo function
        } else if (event.key === "y") {
          event.preventDefault(); // Prevent default behavior
          redo(); // Call redo function
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown); // Cleanup listener
    };
  }, [undo, redo]); // Dependencies to ensure the latest functions are used

  return (
    <Flex height="100%" flexDirection="column">
      <Box flex={1} position="relative">
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          snapToGrid={true}
          proOptions={{ hideAttribution: true }}
        >
          <Background color="#ccc" variant={BackgroundVariant.Cross} />
          <Panel position="top-left">
            <VStack alignItems={"flex-start"}>
              <Button
                ml={2}
                colorScheme="blue"
                onClick={onDrawerOpen} // Open drawer
                isDisabled={isLoading}
                size={"sm"}
              >
                Add Node
              </Button>
              <Button
                ml={2}
                colorScheme="blue"
                onClick={onGlobalPromptOpen} // Open modal for global prompt
                isDisabled={isLoading}
                size={"sm"}
              >
                Global Prompt
              </Button>
              <Button
                ml={2}
                colorScheme="blue"
                onClick={onGlobalKnowledgeOpen} // Open modal for global prompt
                isDisabled={isLoading}
                size={"sm"}
              >
                Global Knowledge
              </Button>
            </VStack>
          </Panel>

          <Panel position="top-right">
            <VStack>
              <HStack>
                <VStack>
                  <Input
                    size={"sm"}
                    bgColor="white"
                    variant="outline"
                    placeholder="Give your agent a name"
                    value={agentName}
                    onChange={(event) => setAgentName(event.target.value)}
                    isDisabled={isLoading}
                  />
                  <Input
                    size={"sm"}
                    bgColor="white"
                    variant="outline"
                    placeholder="Calling Number"
                    value={callNumber}
                    onChange={(event) =>
                      handleCallNumberInput(event.target.value)
                    }
                    isDisabled={isLoading}
                  />
                  <Select
                    size={"sm"}
                    bgColor="white"
                    variant="outline"
                    value={selectedVoiceId}
                    onChange={(e) => setSelectedVoiceId(e.target.value)}
                    isDisabled={isLoading}
                  >
                    {voiceConfigs.map((config) => (
                      <option key={config.agent_id} value={config.agent_id}>
                        {config.agent_name}
                      </option>
                    ))}
                  </Select>
                </VStack>

                <VStack>
                  <Button
                    size={"sm"}
                    colorScheme="blue"
                    onClick={saveAgentState}
                    isLoading={isLoading}
                  >
                    Save
                  </Button>
                  <Button
                    size={"sm"}
                    colorScheme={isCallActive ? "red" : "green"}
                    onClick={handleTestAgent}
                    isLoading={isRetellCallLoading}
                  >
                    {isCallActive ? "Stop Call" : "Call"}
                  </Button>
                </VStack>
              </HStack>
              <Button
                ml={2}
                colorScheme="blue"
                onClick={openChatModal} // Open chat modal
                isDisabled={isLoading}
                size={"sm"}
                width={"100%"}
              >
                Chat
              </Button>
            </VStack>
          </Panel>
          <Controls>
            <ControlButton onClick={undo} disabled={historyIndex === 0}>
              <FaUndo />
            </ControlButton>
            <ControlButton
              onClick={redo}
              disabled={historyIndex >= history.length - 1}
            >
              <FaRedo />
            </ControlButton>
          </Controls>
        </ReactFlow>
      </Box>
      <NewNodeList
        isOpen={isDrawerOpen}
        onClose={onDrawerClose}
        nodeTypes={availableNodeTypes}
        handleNodeClick={handleAddNode}
      />

      {/* Chakra UI Modal for Global Prompt */}
      <ModalWrapper
        isOpen={isGlobalPromptOpen}
        onClose={onGlobalPromptClose}
        footer={true}
        header="Global Prompt"
      >
        <Textarea
          value={globalPrompt} // Bind to globalPrompt state
          onChange={(e) => setGlobalPrompt(e.target.value)} // Update state on change
          placeholder="Enter your global prompt here..."
          size="sm"
        />
      </ModalWrapper>

      {/* Chakra UI Modal for Global Knowledge */}
      <ModalWrapper
        isOpen={isGlobalKnowledgeOpen}
        onClose={onGlobalKnowledgeClose}
        footer={true}
        header="Global Knowledge"
        size="4xl"
      >
        <MarkDownEditor
          value={baseInformation.text}
          setValue={(value) => {
            setBaseInformation({
              ...baseInformation,
              text: value,
            });
          }}
        />
      </ModalWrapper>

      {/* Chat Modal */}
      <ModalWrapper
        isOpen={isChatModalOpen}
        onClose={onChatModalClose}
        size="xl"
        footer={false}
        header="Chat Agent"
      >
        <ChatAgent config={chatConfig} graphType="cpw" />
      </ModalWrapper>
    </Flex>
  );
};

export default CPWBuilder;
