import { AgentCreateParams, AgentUpdateParams } from "../../pages/Voices/type";
import { api } from "../apiService";

export enum GraphType {
  CPW = "cpw",
  MNA = "mna",
}

export const registerCallDemo = async (testConfig?: Record<string, any>, graphType?: GraphType, voiceId?: string) => {
  const response = await api.post("/retell/register-web-call", { testConfig: testConfig, graphType: graphType, voiceId: voiceId });
  return response.data;
};

export const getVoices = async () => {
  const response = await api.get("/retell/list-voices");
  return response.data;
};

export const getVoiceConfigurations = async () => {
  const response = await api.get("/retell/list-agents");
  return response.data;
};

export const getVoiceConfiguration = async (agentId: string) => {
  const response = await api.get(`/retell/get-agent/${agentId}`);
  return response.data;
};

export const deleteVoiceConfiguration = async (agentId: string) => {
  const response = await api.delete(`/retell/delete-agent/${agentId}`);
  return response.data;
};

export const createVoiceConfiguration = async (voiceConfig: AgentCreateParams) => {
  const response = await api.post("/retell/create-agent", voiceConfig);
  return response.data;
};

export const updateVoiceConfiguration = async (agentId: string, voiceConfig: AgentUpdateParams) => {
  const response = await api.patch(`/retell/update-agent/${agentId}`, voiceConfig);
  return response.data;
};