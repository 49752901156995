import React from "react";
import { Text, Textarea } from "@chakra-ui/react";
import { NodeData } from "../type";

interface KnowledgeBaseSectionProps {
  nodeData: NodeData;
  setNodeData: React.Dispatch<React.SetStateAction<NodeData>>;
}

const KnowledgeBaseSection: React.FC<KnowledgeBaseSectionProps> = ({
  nodeData,
  setNodeData,
}) => {
  return (
    <>
      <Text>Knowledge Base:</Text>
      <Textarea
        value={nodeData.knowledgeBaseText}
        onChange={(e) =>
          setNodeData({
            ...nodeData,
            knowledgeBaseText: e.target.value,
          })
        }
        placeholder="Add knowledge Base"
        size="md"
        resize="vertical"
        overflowY="auto"
      />
    </>
  );
};

export default KnowledgeBaseSection;
