import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import LandingPage from "./pages/LandingPageV2";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import AgentBuilder from "./pages/AgentBuilder";
import ProtectedRoute from "./components/ProtectedRoute";
import NotFound from "./pages/NotFound";
import "./fonts/the-youngest-serif-display.ttf";
import ChatPage from "./pages/Chat";
import CPWBuilder from "./pages/CPWBuilder";
import Agents from "./components/Agents";
import Voices from "./pages/Voices";
import Settings from "./pages/Settings/Settings";
import VoiceForm from "./pages/Voices/VoiceForm";
import { GoogleOAuthProvider } from "@react-oauth/google";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID!;
console.log(clientId);

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/cpw/new",
    element: <CPWBuilder />,
  },
  {
    path: "/login",
    element: <Login />,
  },

  {
    path: "/dashboard",
    element: (
      <GoogleOAuthProvider clientId={clientId}>
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      </GoogleOAuthProvider>
    ),
    children: [
      {
        path: "agents",
        element: <Agents />,
      },
      {
        path: "agent/mna/new",
        element: <AgentBuilder />,
      },
      {
        path: "agent/mna/:agentId",
        element: <AgentBuilder />,
      },
      {
        path: "agent/cpw/new",
        element: <CPWBuilder />,
      },
      {
        path: "agent/cpw/:agentId",
        element: <CPWBuilder />,
      },
      {
        path: "chats",
        element: <ChatPage />,
      },
      {
        path: "voices",
        element: <Voices />,
      },
      {
        path: "voice/new",
        element: <VoiceForm />,
      },
      {
        path: "voice/edit/:agentId",
        element: <VoiceForm />,
      },
      {
        path: "agent-metrics",
        element: <div>Agent Metrics</div>,
      },
      {
        path: "contacts",
        element: <div>Contacts</div>,
      },
      {
        path: "knowledge-base",
        element: <div>Knowledge Base</div>,
      },
      {
        path: "campaigns",
        element: <div>Campaigns</div>,
      },
      {
        path: "settings",
        element: <Settings />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

const App: React.FC = () => {
  return (
    <ChakraProvider>
      <RouterProvider router={router} />
    </ChakraProvider>
  );
};

export default App;
